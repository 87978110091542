import 'minireset.css'
import 'styles/globals.css'

import * as Fathom from 'fathom-client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Head from 'next/head'
import React, { useEffect } from 'react'
import honey from 'services/honey'
import { useRouter } from 'next/router'

// TODO(ibash) ensure bugsnag is correct here
// ref: https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-715329581
Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: process.env.buildId,
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_APP_ENV
})

if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
  // bugsang swallows errors...
  const originalNotify = Bugsnag.notify
  Bugsnag.notify = function (error) {
    console.error(error)
    return originalNotify.apply(this, arguments)
  }
}
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// TODO(ibash) add bugsnag here
// It appears bugsnag doesn't support next.js easily, so this needs a proper
// investigation + implementation.
//
// ref: https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-715329581
export default function App({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    // Initialize Fathom when the app loads
    Fathom.load('BDVRDPKK', {
      includedDomains: ['summon.me']
    })
    honey.track('view')

    function onRouteChangeComplete() {
      Fathom.trackPageview()
      honey.track('view')
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Summon</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  )
}
